import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
   localeLinkNavbar,
   localeLinkRedirect,
} from '@/content/translate/locale-link'
import Location from '../general/Location'
import { useHookCheckTranslateLink } from '@/helper/useTranslateLink'
import { useHookLayoutContext } from '../hooks/MainContext'
import { isMobile } from '@/helper/checkDeviceMedia'
import { pathLogoBlackNoSlogan, pathLogoWhiteNoSlogan } from '@/config/pathLogo'

const configPackage = (name, url) => ({ name, url })

const baliPacket = [
   configPackage('Lite', '/product/lite'),
   configPackage('Signature', '/product/signature'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]

const balikpapanPacket = [
   configPackage('Signature', '/product/signature'),
   configPackage('Preferred', '/product/preferred'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]

const samarindaPacket = [
   configPackage('Signature', '/product/signature'),
   configPackage('Preferred', '/product/preferred'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]

const malangPacket = [
   configPackage('Lite', '/product/lite'),
   configPackage('Signature', '/product/signature'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]

const dataBranch = [
   {
      name: 'Bali, Area Kerobokan',
      packet: [...baliPacket],
   },
   {
      name: 'Bali, Area Denpasar',
      packet: [...baliPacket],
   },
   {
      name: 'Balikpapan',
      packet: [...balikpapanPacket],
   },
   {
      name: 'Samarinda',
      packet: [...samarindaPacket],
   },
   {
      name: 'Malang',
      packet: [...malangPacket],
   },
]

function NavbarMobile({ hasScrolled, isNavBlackStyle = false, isCheckCoveragePlatform = false }) {
   const [stickyClass, setStickyClass] = useState('')
   const [isNavbarSticky, setIsNavbarSticky] = useState(false)
   const [isShowNavbarToggler, setIsShowNavbarToggler] = useState(false)
   const [isCollapsed, setIsCollapsed] = useState(false)
   const [packetList, setPacketList] = useState([])

   const isMobileMacthMedia = isMobile()
   const router = useRouter()

   // START CHANGE LANGUAGE
   const { __branch, __locale } = useHookLayoutContext()

   const { __handleSwitchPageByCacheLocale } = useHookCheckTranslateLink()

   const _handleSwitchLang = async (sign = '') => {
      __locale.handle(sign)

      const findURL = !_.isEmpty(localeLinkRedirect[sign])
         ? localeLinkRedirect[sign].find(
              (vm) => vm.pathname === router.pathname
           )
         : {}

      const redirectTo = !_.isEmpty(findURL)
         ? findURL.redirect
         : router.pathname

      // const dataURL =

      // router.replace(router.pathname, redirectTo, {
      //     locale: sign,
      //     // shallow: true,
      //     // scroll: true,
      // })

      __handleSwitchPageByCacheLocale(sign)
      _handleCollapsedNavbar()
   }
   // END CHANGE LANGUAGE

   const _handleCollapsedNavbar = () => {
      if (isCollapsed && isMobileMacthMedia) {
         document.body.classList.remove('has-scroll-menu')
      } else {
         document.body.classList.add('has-scroll-menu')
      }
      setIsCollapsed((current) => !current)
   }

   const _checkLinkActive = (path) => {
      return router.asPath === path ? 'nav-item active' : 'nav-item'
   }

   const _checkIsActive = (path) => (router.asPath === path ? 'active' : '')

   const MotionNavLinkBundle = ({
      passContent = {},
      transitionDelay = 0,
      exitYDelay = 0,
      isTargetBlank = false,
      rel = '',
      isDropdown = false,
      dropdownLink = [],
   }) => (
      <motion.li
         className={[
            isDropdown ? 'dropdown' : '',
            _checkLinkActive(passContent.link),
         ].join(' ')}
         initial={{ y: 80, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         transition={{ delay: transitionDelay }}
         exit={{
            opacity: 0,
            y: 90,
            transition: {
               ease: 'easeInOut',
               delay: exitYDelay,
            },
         }}>
         <div className="grid gap-3 wp-btn-dropdown">
            <Link
               href={passContent.link}
               className={[
                  'nav-link',
                  isDropdown ? 'has-dropdown g-col-11' : 'g-col-12',
                  isNavbarSticky || isNavBlackStyle
                     ? 'text-black text-content-blur'
                     : 'text-white',
                  _checkIsActive(passContent.link),
               ].join(' ')}
               target={isTargetBlank ? '_blank' : ''}
               rel={rel}>
               {passContent.name}
            </Link>

            {isDropdown && dropdownLink.length ? (
               <>
                  <button
                     className="g-col-1 bg-transparent border-0 dropdown-toggle"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                     role="button">
                     <ChevronDownIcon className="arrow-dropdown-icon bg-light-400 rounded-circle" />
                  </button>

                  <ul className="g-col-12 dropdown-menu rounded-0 border-0 pt-0 pb-4">
                     <li>
                        {dropdownLink.map((link, index) => (
                           <Link
                              key={index}
                              href={link.url || link.link}
                              className="dropdown-item fs-24 fw-500 mb-3 p-0">
                              {link.name}
                           </Link>
                        ))}
                     </li>
                  </ul>
               </>
            ) : null}
         </div>
      </motion.li>
   )

   // start animation navbar mobile
   const item = {
      exit: {
         opacity: 0,
         height: 0,
         transition: {
            ease: 'easeInOut',
            duration: 0.3,
            delay: 1,
         },
      },
   }

   useEffect(() => {
      const _handleNavbarAndMobileWidth = () => {
         if (window !== undefined) {
            let windowHeight = window.scrollY

            if (windowHeight > 119) {
               if (isMobileMacthMedia) {
                  setStickyClass('bg-transparent')
                  setIsNavbarSticky(true)
               } else {
                  setStickyClass('blur-background')
                  setIsNavbarSticky(false)
               }
               setIsShowNavbarToggler(true)
            } else {
               setIsNavbarSticky(false)
               setStickyClass('bg-transparent')
               setIsShowNavbarToggler(false)
            }
         }
      }

      window.addEventListener('scroll', _handleNavbarAndMobileWidth)
      window.addEventListener('resize', _handleNavbarAndMobileWidth)

      return () => {
         window.removeEventListener('scroll', _handleNavbarAndMobileWidth)
         window.removeEventListener('resize', _handleNavbarAndMobileWidth)
      }
   }, [isMobileMacthMedia])

   useEffect(() => {
      const tawkToIframe = document.querySelector('.widget-visible iframe')
      tawkToIframe?.style?.removeProperty('display')

      if (isCollapsed) {
         tawkToIframe?.classList?.add('hide-tawk-button')
      } else {
         tawkToIframe?.classList?.remove('hide-tawk-button')
      }

      const dataScrollContainerAttribute =
         document.querySelector('.smooth-scroll')
      dataScrollContainerAttribute.removeAttribute('data-scroll-container')
   }, [isCollapsed])

   useEffect(() => {
      setPacketList(
         dataBranch.filter((vm) => vm.name === __branch.data.name)[0]?.packet
      )
   }, [__branch.data.name])

   return (
      <div
         className="wp-nav-mobile"
         style={{ position: 'relative', zIndex: 999 }}>
         <nav
            className="navbar navbar-expand-lg navbar-light"
            style={{
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               padding: '0',
               height: '94px',
            }}>
            <div className="container-fluid px-4 px-md-5">
               <div
                  className="w-100 d-flex align-items-center"
                  style={{ height: '50px' }}>
                  <Link href="/" className="navbar-brand me-auto">
                     {(isNavbarSticky && !isMobileMacthMedia) ||
                     isNavBlackStyle ? (
                        <img
                           src={pathLogoBlackNoSlogan}
                           // src="/images/logo/logo-gx-black.svg"
                           alt="Logo GlobalXtreme"
                           className={[
                              'gx-header-logo',
                              isNavBlackStyle ? 'd-block' : 'd-none d-lg-block',
                           ].join(' ')}
                           width="auto"
                           height="auto"
                           loading="lazy"
                        />
                     ) : (
                        <img
                           src={pathLogoWhiteNoSlogan}
                           // src="/images/logo/logo-gx-white.svg"
                           alt="Logo GlobalXtreme"
                           className="gx-header-logo d-block"
                           width="auto"
                           height="auto"
                           loading="lazy"
                        />
                     )}
                  </Link>

                  <button
                     className={[
                        'navbar-toggler border-0 fixed-menu-mobile',
                        !isCollapsed ? 'collapsed' : '',
                        isShowNavbarToggler || isCollapsed
                           ? 'custom-navbar-toggler'
                           : 'hide-box-shadow',
                        !isCollapsed && hasScrolled ? 'has-scrolled' : '',
                     ].join(' ')}
                     type="button"
                     onClick={_handleCollapsedNavbar}>
                     <div className="line-wrapper">
                        <span
                           className={`custom-toggler-icon ${
                              !isNavBlackStyle || isNavbarSticky || isCollapsed
                                 ? 'white'
                                 : 'black'
                           } first-line`}></span>
                        <span
                           className={`custom-toggler-icon d-none ${
                              !isNavBlackStyle || isNavbarSticky || isCollapsed
                                 ? 'white'
                                 : 'black'
                           } second-line`}></span>
                        <span
                           className={`custom-toggler-icon ${
                              !isNavBlackStyle || isNavbarSticky || isCollapsed
                                 ? 'white'
                                 : 'black'
                           } third-line`}></span>
                     </div>
                  </button>
               </div>
            </div>
         </nav>

         <AnimatePresence>
            {isCollapsed && (
               <motion.div
                  className="menu_container px-4 px-md-5"
                  variants={item}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: '100vh', opacity: 1, inset: 0 }}
                  transition={{ duration: 0.5 }}
                  exit="exit">
                  <div className="wp-menu-mobile">
                     {isCollapsed ? (
                        <Link href="/" className="navbar-brand border-0 p-0">
                           <img
                              src={pathLogoBlackNoSlogan}
                              // src="/images/logo/logo-gx-black.svg"
                              alt="Logo GlobalXtreme"
                              className="custom-gx-header-logo"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />
                        </Link>
                     ) : null}

                     {!isCheckCoveragePlatform ? (
                        <ul className="navbar-nav navigation-menu w-100">
                           <MotionNavLinkBundle
                              passContent={localeLinkNavbar.home[router.locale]}
                              transitionDelay=".8"
                              exitYDelay=".7"
                           />
                           <MotionNavLinkBundle
                              passContent={localeLinkNavbar.product[router.locale]}
                              transitionDelay=".7"
                              exitYDelay=".6"
                              isDropdown
                              dropdownLink={packetList}
                           />
                           <MotionNavLinkBundle
                              passContent={localeLinkNavbar.about[router.locale]}
                              transitionDelay=".6"
                              exitYDelay=".5"
                              isDropdown
                              dropdownLink={[
                                 localeLinkNavbar.about[router.locale],
                                 localeLinkNavbar.career[router.locale],
                                 localeLinkNavbar.blog[router.locale],
                              ]}
                           />
                           <MotionNavLinkBundle
                              passContent={
                                 localeLinkNavbar.contactUS[router.locale]
                              }
                              transitionDelay=".5"
                              exitYDelay=".4"
                           />
                           <MotionNavLinkBundle
                              passContent={{
                                 name: 'Log In',
                                 link: 'https://customer.globalxtreme.net/',
                              }}
                              transitionDelay=".4"
                              exitYDelay=".3"
                              isTargetBlank
                              rel="noreferrer"
                           />
                        </ul>
                     ) : null}
                  </div>

                  <motion.div
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ delay: 0.35 }}
                     exit={{
                        opacity: 0,
                        transition: {
                           ease: 'easeInOut',
                           delay: 1.5,
                        },
                     }}
                     className="wp-menu-mobile-footer w-100 d-flex justify-content-between align-items-center p-4 px-md-5">
                     <motion.div
                        className="w-100 me-5"
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        exit={{
                           opacity: 0,
                           x: 80,
                           transition: {
                              ease: 'easeInOut',
                              delay: 0.3,
                           },
                        }}>
                        <Location
                           menuPlacementTop
                           _handleCollapsedNavbar={_handleCollapsedNavbar}
                        />
                     </motion.div>

                     <motion.ul
                        className="navbar-nav w-auto d-flex flex-row align-items-center justify-content-center space-language"
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        exit={{
                           opacity: 0,
                           x: 80,
                           transition: {
                              ease: 'easeInOut',
                              delay: 0.1,
                           },
                        }}>
                        <li
                           className={
                              'nav-lang-btn ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white') +
                              (__locale.data === 'en' ? ' active' : '')
                           }
                           onClick={() => _handleSwitchLang('en')}>
                           EN
                        </li>
                        <li
                           className={
                              'px-3 ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white')
                           }>
                           |
                        </li>
                        <li
                           className={
                              'nav-lang-btn ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white') +
                              (__locale.data === 'id' ? ' active' : '')
                           }
                           onClick={() => _handleSwitchLang('id')}>
                           ID
                        </li>
                     </motion.ul>
                  </motion.div>
               </motion.div>
            )}
         </AnimatePresence>
      </div>
   )
}

export default NavbarMobile
