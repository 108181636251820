import { TimelineLite, TimelineMax, TweenMax } from 'gsap'
import { gsap } from 'gsap/dist/gsap.js'
import { SplitText } from './splitText.js'

const contoh = () => {
   const text = document.querySelector('h1.test')
   const splittedText = new SplitText(text) // or new SplitText("h1.test")

   const textTimeline = new TimelineLite()

   textTimeline.staggerFromTo(
      splittedText.chars,
      0.5,
      // From
      {
         rotateZ: 360,
         opacity: 0,
      },
      // To
      {
         rotateZ: 0,
         opacity: 1,
      },
      0.02
   )
}

export const _gsapOnScrolling = () => {
   let heroes = gsap.utils.toArray('.hero')

   // create an animation for each hero section
   heroes.forEach((hero) => {
      let split = new (hero.querySelector('._split_text'),
         {
            type: 'chars,words',
         })(),
         tl = gsap.timeline({
            scrollTrigger: {
               trigger: hero,
               start: 'top center',
               toggleActions: 'play none none reverse',
            },
         })
      tl.to(hero.querySelector('.sup_hero'), {
         opacity: 1,
         x: -50,
         ease: 'back',
      }).from(split.chars, {
         opacity: 0,
         y: 50,
         rotation: 1, // to prevent pixel snapping (unrelated to GSAP - it's a browser thing)
         duration: 2,
         ease: 'back',
         stagger: 0.05,
      })
   })
}

export const _animate_show_navbar = () => {
   TweenMax.from('._gsap_navbar_animation', 0.5, {
      delay: 0.4,
      // ease: 'power0.easeNone',
      // opacity: 0,
      y: -100,
   })
}

export const _animate_header_page = (
   imgSlider = '',
   txtSlide = '',
   txtDescription = ''
) => {
   if (imgSlider) {
      TweenMax.from(imgSlider, 1.3, {
         delay: 0.6,
         ease: 'power3.inOut',
         opacity: 0,
      })
   }

   if (txtSlide) {
      TweenMax.from(txtSlide, 1, {
         delay: 1.2,
         opacity: 0,
         y: 20,
         ease: 'power3.inOut',
      })
   }

   if (txtDescription) {
      TweenMax.from(txtDescription, 1, {
         delay: 1.25,
         opacity: 0,
         y: 20,
         ease: 'power3.inOut',
      })
   }
}

export const _animate_header_page_general = () => {}

export const _animate_scroller = () => {
   let heroes = gsap.utils.toArray('.gsap-wp-split-text')

   // create an animation for each hero section
   heroes.forEach((hero) => {
      let split = new SplitText(hero.querySelector('.gsap-scroll-split-text'), {
            type: 'chars,words',
         }),
         tl = gsap.timeline({
            scrollTrigger: {
               trigger: hero,
               start: 'top center',
               toggleActions: 'play none none reverse',
            },
         })
      tl.to(hero.querySelector('.gsap-sub-split-text'), {
         opacity: 1,
         x: -50,
         ease: 'back',
      }).from(split.chars, {
         opacity: 0,
         y: 50,
         rotation: 1, // to prevent pixel snapping (unrelated to GSAP - it's a browser thing)
         duration: 2,
         ease: 'back',
         stagger: 0.05,
      })
   })
}

export const _animate_header_page_blog_detail = () => {
   TweenMax.from('._gsap_txt_main', 1, {
      delay: 1.2,
      opacity: 0,
      y: 20,
      ease: 'power3.inOut',
   })

   TweenMax.from('._gsap_txt_second_1', 1.1, {
      delay: 1.3,
      opacity: 0,
      y: 20,
      ease: 'power3.inOut',
   })
   TweenMax.from('._gsap_txt_second_2', 1.2, {
      delay: 1.4,
      opacity: 0,
      y: 20,
      ease: 'power3.inOut',
   })

   TweenMax.from('._gsap_img_animate', 1.4, {
      delay: 1.6,
      ease: 'power3.inOut',
      opacity: 0,
      // width: 0,
      // height: 100,
      y: 50,
   })
}
