import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { MapPinIcon } from '@heroicons/react/24/solid'
import { useHookLayoutContext } from '@/component/hooks/MainContext'
import _ from 'lodash'
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'

const Control = (props) => {
   return (
      <components.Control {...props}>
         <MapPinIcon height={14} width={14} className="map-icon" />
         {props.children}
      </components.Control>
   )
}

const DropdownIndicator = (props) => {
   return (
      <components.DropdownIndicator {...props}>
         <ArrowDownCircleIcon height={18} width={18} className="arrow-icon" />
      </components.DropdownIndicator>
   )
}

const Location = (props) => {
   const {
      hasDropdownIndicator = false,
      className = '',
      classNamePrefix = '',
      id = '',
      placeholder = '',
      menuPlacementTop = false,
      _handleCollapsedNavbar = () => {},
   } = props
   const { __branch } = useHookLayoutContext()

   const instanceId = 'slc-gx-location'
   const inputId = 'slc-input-gx-location'
   const idLabelLoc = 'label-gx-location'

   return (
      <>
         <label className="d-none" id={idLabelLoc} htmlFor={inputId}>
            Location
         </label>
         <Select
            // name={name}
            aria-labelledby={idLabelLoc}
            id={id}
            options={__branch.list}
            value={!_.isEmpty(__branch.data) ? __branch.data : null}
            onChange={(opt) => {
               __branch.handle(opt)
               _handleCollapsedNavbar()
            }}
            placeholder={placeholder || 'Choose Location'}
            className={[className || '', 'slc-container'].join(' ')}
            instanceId={instanceId}
            inputId={inputId}
            classNamePrefix={[classNamePrefix || '', 'slc-prefix'].join(' ')}
            menuPlacement={menuPlacementTop ? 'top' : 'bottom'}
            isSearchable={false}
            components={
               hasDropdownIndicator
                  ? {
                       Control,
                       DropdownIndicator,
                    }
                  : {
                       Control,
                    }
            }
            styles={{
               control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#ffffff' : '#ffffff',
                  ':hover': {
                     borderColor: 'rgba(255, 255, 255, 0.2)',
                  },
               }),
               input: (baseStyles, state) => ({
                  ...baseStyles,
                  color: '#ffffff',
               }),
               indicatorSeparator: (baseStyles, state) => ({
                  ...baseStyles,
                  display: 'none',
               }),
               dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  display: hasDropdownIndicator ? 'block' : 'none',
               }),
               menu: (baseStyles, state) => ({
                  ...baseStyles,
                  zIndex: 2,
               }),
               option: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '0.875rem',
               }),
               noOptionsMessage: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '0.875rem',
               }),
            }}
            theme={(theme) => ({
               ...theme,
               colors: {
                  ...theme.colors,
                  primary: '#f9c900',
                  primary50: '#f9c900',
                  primary25: 'rgb(255,246,195)',
               },
            })}
         />
      </>
   )
}

export default Location
