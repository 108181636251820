import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { isBrowser } from 'react-device-detect'
import { motion, AnimatePresence } from 'framer-motion'
import _ from 'lodash'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
   localeLinkNavbar,
   localeLinkRedirect,
} from '@/content/translate/locale-link'
import { _animate_show_navbar } from '@/helper/gsap-crack/gsap-main-crack'
import { useHookLayoutContext } from '@/component/hooks/MainContext'
import { useHookCheckTranslateLink } from '@/helper/useTranslateLink'
import { isMobile } from '@/helper/checkDeviceMedia'
import Location from '@/component/general/Location'
import NavbarMobile from './NavbarMobile'
import { pathLogoBlackNoSlogan, pathLogoWhiteNoSlogan } from '@/config/pathLogo'

const configPackage = (name, url) => ({ name, url })

const baliPacket = [
   configPackage('Lite', '/product/lite'),
   configPackage('Signature', '/product/signature'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]
const baliPacketId = [
   configPackage('Lite', '/produk/lite'),
   configPackage('Signature', '/produk/signature'),
   configPackage('Dedicated Internet Access', '/produk/dedicated'),
   configPackage('Customized Services', '/produk/customized-services'),
]

const balikpapanPacket = [
   configPackage('Signature', '/product/signature'),
   configPackage('Preferred', '/product/preferred'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]
const balikpapanPacketId = [
   configPackage('Signature', '/produk/signature'),
   configPackage('Preferred', '/produk/preferred'),
   configPackage('Dedicated Internet Access', '/produk/dedicated'),
   configPackage('Customized Services', '/produk/customized-services'),
]

const samarindaPacket = [
   configPackage('Signature', '/product/signature'),
   configPackage('Preferred', '/product/preferred'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]
const samarindaPacketId = [
   configPackage('Signature', '/produk/signature'),
   configPackage('Preferred', '/produk/preferred'),
   configPackage('Dedicated Internet Access', '/produk/dedicated'),
   configPackage('Customized Services', '/produk/customized-services'),
]

const malangPacket = [
   configPackage('Lite', '/product/lite'),
   configPackage('Signature', '/product/signature'),
   configPackage('Dedicated Internet Access', '/product/dedicated'),
   configPackage('Customized Services', '/product/customized-services'),
]
const malangPacketId = [
   configPackage('Lite', '/produk/lite'),
   configPackage('Signature', '/produk/signature'),
   configPackage('Dedicated Internet Access', '/produk/dedicated'),
   configPackage('Customized Services', '/produk/customized-services'),
]

const dataBranch = [
   {
      name: 'Bali, Area Kerobokan',
      packet: {
         en: [...baliPacket],
         id: [...baliPacketId],
      },
   },
   {
      name: 'Bali, Area Denpasar',
      packet: {
         en: [...baliPacket],
         id: [...baliPacketId],
      },
   },
   {
      name: 'Balikpapan',
      packet: {
         en: [...balikpapanPacket],
         id: [...balikpapanPacketId],
      },
   },
   {
      name: 'Samarinda',
      packet: {
         en: [...samarindaPacket],
         id: [...samarindaPacketId],
      },
   },
   {
      name: 'Malang',
      packet: {
         en: [...malangPacket],
         id: [...malangPacketId],
      },
   },
]

const NavbarDefault = ({
   hasScrolled,
   isNavBlackStyle = false,
   isNavbarFixed = false,
   isCheckCoveragePlatform = false,
}) => {
   const { scroll } = useLocomotiveScroll()
   const [stickyClass, setStickyClass] = useState('')
   const [isNavbarSticky, setIsNavbarSticky] = useState(false)
   const [isShowNavbarToggler, setIsShowNavbarToggler] = useState(false)
   const [isCollapsed, setIsCollapsed] = useState(false)
   const [fixedMenuMobile, setFixedMenuMobile] = useState('')
   const [isClient, setIsClient] = useState(false)
   const [packetList, setPacketList] = useState([])

   const isMobileMacthMedia = isMobile()

   const [isScrollOn, setIsScrollOn] = useState(false)
   const [isNavbarVisible, setIsNavbarVisible] = useState(true)
   const [dataScrollTop, setDataScrollTop] = useState({
      last: 0,
      on: 0,
   })

   const router = useRouter()
   const localeByPath = router.locale || 'en'

   // START CHANGE LANGUAGE
   const { __branch, __locale } = useHookLayoutContext()

   const { __handleSwitchPageByCacheLocale } = useHookCheckTranslateLink()

   const _handleSwitchLang = async (sign = '') => {
      __locale.handle(sign)

      const findURL = !_.isEmpty(localeLinkRedirect[sign])
         ? localeLinkRedirect[sign].find(
              (vm) => vm.pathname === router.pathname
           )
         : {}

      const redirectTo = !_.isEmpty(findURL)
         ? findURL.redirect
         : router.pathname

      // const dataURL =

      // router.replace(router.pathname, redirectTo, {
      //     locale: sign,
      //     // shallow: true,
      //     // scroll: true,
      // })

      __handleSwitchPageByCacheLocale(sign)
      if (isMobileMacthMedia) {
         _handleCollapsedNavbar()
      }
   }
   // END CHANGE LANGUAGE

   const _handleCollapsedNavbar = () => {
      if (isCollapsed && isMobileMacthMedia) {
         document.body.classList.remove('has-scroll-menu')
      } else {
         document.body.classList.add('has-scroll-menu')
      }
      setIsCollapsed((current) => !current)
   }

   const newValue = { ...dataScrollTop }
   let hLast = 0
   const _handleNavbarAndMobileWidth = async (
      isMobileWidth = false,
      pageScrollY = 0
   ) => {
      let windowHeight = pageScrollY

      if (windowHeight > 119) {
         if (isMobileMacthMedia) {
            setStickyClass('bg-transparent')
         } else {
            setStickyClass('blur-background')
         }
         setIsNavbarSticky(true)
         setIsShowNavbarToggler(true)
      } else {
         if (isNavBlackStyle && windowHeight !== 0) {
            setStickyClass('blur-background')
         } else {
            setStickyClass('bg-transparent')
         }
         setIsNavbarSticky(false)
         setIsShowNavbarToggler(false)
      }

      if (windowHeight > 92 && isMobileWidth) {
         setFixedMenuMobile('fixed-menu-mobile')
      } else {
         setFixedMenuMobile('')
      }

      if (pageScrollY > hLast) {
         setIsNavbarVisible(false)
      } else if (pageScrollY < hLast) {
         setIsNavbarVisible(true)
      }

      hLast = pageScrollY
   }

   const _handleOnScroll = async () => {
      // const newValue = { ...dataScrollTop }
      scroll.on('scroll', async (callValue) => {
         // console.log('callValue: ', callValue)
         const valueY = callValue.scroll.y

         if (valueY) {
            newValue['on'] = valueY
            newValue['last'] = valueY

            await setDataScrollTop((preValue) => ({
               ...preValue,
               ...newValue,
            }))
            // console.log('dataScrollTop: ', newValue)
            setIsNavbarSticky(true)
            await _handleNavbarAndMobileWidth(true, valueY)
         } else if (valueY < 200) {
            setIsNavbarVisible(true)
            setStickyClass('')
            setIsNavbarSticky(false)
            // setLastScrollTop(0)
            // const newValue = { ...dataScrollTop }
            newValue['last'] = 0
            newValue['on'] = 0
            setDataScrollTop((preValue) => ({
               ...preValue,
               ...newValue,
            }))
            await _handleNavbarAndMobileWidth(false, valueY)
         }
         // alert('hello')
      })
   }

   useEffect(() => {
      if (scroll && !isScrollOn) {
         setIsScrollOn(!isScrollOn)
         _handleOnScroll()
      }
   }, [scroll])

   // const router = useRouter()
   const _checkLinkActive = (path) => {
      return router.asPath === path ? 'nav-item active' : 'nav-item'
   }

   const NavLinkBundle = ({
      passContent = {},
      isDropdown = false,
      dropdownLink = [],
   }) => (
      <li
         className={[
            'h-100 d-flex align-items-center px-2 cursor-pointer',
            _checkLinkActive(passContent.link, isDropdown),
            isDropdown ? 'dropdown gap-2' : '',
         ].join(' ')}>
         <Link
            className={[
               'nav-link',
               isNavbarSticky || isNavBlackStyle
                  ? 'text-black text-content-blur'
                  : 'text-white',
            ].join(' ')}
            href={passContent.link}>
            {passContent.name}
         </Link>

         {isDropdown && dropdownLink.length ? (
            <>
               <button
                  className="h-100 bg-transparent border-0 dropdown-toggle d-flex align-items-center"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  role="button"
                  type="button"
                  aria-labelledby={passContent.link}>
                  <ChevronDownIcon
                     className="arrow-dropdown-icon-desktop cursor-pointer"
                     width={14}
                     height={14}
                  />
               </button>

               <div className="dropdown-menu" id={passContent.link}>
                  {dropdownLink.map((link, index) => (
                     <Link
                        key={index}
                        href={link.url || link.link}
                        className="dropdown-item">
                        {link.name}
                     </Link>
                  ))}
               </div>
            </>
         ) : null}
      </li>
   )

   const _checkIsActive = (path) => (router.asPath === path ? 'active' : '')

   const MotionNavLinkBundle = ({
      passContent = {},
      transitionDelay = 0,
      exitYDelay = 0,
      isTargetBlank = false,
      rel = '',
      isDropdown = false,
      dropdownLink = [],
   }) => (
      <motion.li
         className={[
            isDropdown ? 'dropdown' : '',
            _checkLinkActive(passContent.link),
         ].join(' ')}
         initial={{ y: 80, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         transition={{ delay: transitionDelay }}
         exit={{
            opacity: 0,
            y: 90,
            transition: {
               ease: 'easeInOut',
               delay: exitYDelay,
            },
         }}>
         <div className="grid gap-3 wp-btn-dropdown">
            <Link
               href={passContent.link}
               className={[
                  'nav-link',
                  isDropdown ? 'has-dropdown g-col-11' : 'g-col-12',
                  isNavbarSticky || isNavBlackStyle
                     ? 'text-black text-content-blur'
                     : 'text-white',
                  _checkIsActive(passContent.link),
               ].join(' ')}
               target={isTargetBlank ? '_blank' : ''}
               rel={rel}>
               {passContent.name}
            </Link>

            {isDropdown && dropdownLink.length ? (
               <>
                  <button
                     className="g-col-1 bg-transparent border-0 dropdown-toggle"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                     role="button">
                     <ChevronDownIcon className="arrow-dropdown-icon bg-light-400 rounded-circle" />
                  </button>

                  <ul className="g-col-12 dropdown-menu rounded-0 border-0 pt-0 pb-4">
                     <li>
                        {dropdownLink.map((link, index) => (
                           <Link
                              key={index}
                              href={link.url || link.link}
                              className="dropdown-item fs-24 fw-500 mb-3 p-0">
                              {link.name}
                           </Link>
                        ))}
                     </li>
                  </ul>
               </>
            ) : null}
         </div>
      </motion.li>
   )

   // start animation navbar mobile
   const item = {
      exit: {
         opacity: 0,
         height: 0,
         transition: {
            ease: 'easeInOut',
            duration: 0.3,
            delay: 1,
         },
      },
   }

   useEffect(() => {
      _animate_show_navbar()
      if (!isBrowser) {
         setIsClient(true)
      }

      if (document.body.classList.contains('has-scroll-menu')) {
         document.body.classList.remove('has-scroll-menu')
      }
   }, [])

   useEffect(() => {
      if (localeByPath) {
         setPacketList(
            dataBranch.filter((vm) => vm.name === __branch.data.name)[0]
               ?.packet[localeByPath]
         )
      }
   }, [__branch.data.name, localeByPath])

   useEffect(() => {
      __locale.handle(localeByPath)
   }, [localeByPath])

   return !isClient ? (
      <div
         className="wp-nav-mobile"
         style={{ position: 'relative', zIndex: 999 }}>
         <header
            className={[
               'px-4 px-md-5',
               stickyClass,
               isNavbarFixed
                  ? ''
                  : isNavbarVisible || isMobileMacthMedia
                  ? 'visible'
                  : 'invisible',
               isNavBlackStyle ? 'navbar-black-style' : '',
               !isNavbarSticky ? 'hide-box-shadow' : '',
            ].join(' ')}
            style={
               isNavbarFixed
                  ? {
                       transition: 'background-color 300ms linear',
                    }
                  : {
                       transform: 'translate(0, -93px)',
                       transition: '300ms',
                    }
            }>
            <div
               className={[
                  !isNavBlackStyle && !isNavbarSticky
                     ? 'border-navbar'
                     : isNavBlackStyle && !isNavbarSticky
                     ? 'border-navbar-black'
                     : '',
               ].join(' ')}>
               <nav
                  className="navbar navbar-expand-lg navbar-light p-0"
                  style={
                     isMobileMacthMedia
                        ? { height: '94px' }
                        : { height: '78px' }
                  }>
                  <div className="container-fluid h-100 px-0">
                     <div className="w-100 h-100 d-flex align-items-center">
                        <Link href="/" className="navbar-brand me-auto">
                           {(isNavbarSticky && !isMobileMacthMedia) ||
                           isNavBlackStyle ? (
                              <img
                                 src={pathLogoBlackNoSlogan}
                                 // src="/images/logo/logo-gx-black.svg"
                                 alt="Logo GlobalXtreme"
                                 className="gx-header-logo d-none d-lg-block"
                                 width="auto"
                                 height="auto"
                                 loading="lazy"
                              />
                           ) : (
                              <img
                                 src={pathLogoWhiteNoSlogan}
                                 // src="/images/logo/logo-gx-white.svg"
                                 alt="Logo GlobalXtreme"
                                 className="gx-header-logo d-none d-lg-block"
                                 width="auto"
                                 height="auto"
                                 loading="lazy"
                              />
                           )}
                        </Link>

                        <button
                           className={[
                              'navbar-toggler border-0',
                              !isCollapsed ? 'collapsed' : '',
                              isShowNavbarToggler || isCollapsed
                                 ? 'custom-navbar-toggler'
                                 : '',
                              fixedMenuMobile,
                              !isCollapsed && hasScrolled ? 'has-scrolled' : '',
                           ].join(' ')}
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#navbarSupportedContent"
                           aria-controls="navbarSupportedContent"
                           aria-expanded="false"
                           aria-label="Toggle navigation"
                           onClick={_handleCollapsedNavbar}>
                           <div className="line-wrapper">
                              <span
                                 className={`custom-toggler-icon ${
                                    !isNavBlackStyle ||
                                    isNavbarSticky ||
                                    isCollapsed
                                       ? 'white'
                                       : 'black'
                                 } first-line`}></span>
                              <span
                                 className={`custom-toggler-icon d-none ${
                                    !isNavBlackStyle ||
                                    isNavbarSticky ||
                                    isCollapsed
                                       ? 'white'
                                       : 'black'
                                 } second-line`}></span>
                              <span
                                 className={`custom-toggler-icon ${
                                    !isNavBlackStyle ||
                                    isNavbarSticky ||
                                    isCollapsed
                                       ? 'white'
                                       : 'black'
                                 } third-line`}></span>
                           </div>
                        </button>

                        <div
                           className="collapse navbar-collapse custom-sidenav h-100"
                           id="navbarSupportedContent">
                           {!isCheckCoveragePlatform ? (
                              <>
                                 <ul className="navbar-nav ms-auto navigation-menu h-100 align-items-center gap-3">
                                    <NavLinkBundle
                                       passContent={
                                          localeLinkNavbar.home[router.locale]
                                       }
                                    />
                                    <NavLinkBundle
                                       passContent={
                                          localeLinkNavbar.product[
                                             router.locale
                                          ]
                                       }
                                       isDropdown
                                       dropdownLink={packetList}
                                    />
                                    <NavLinkBundle
                                       passContent={
                                          localeLinkNavbar.about[router.locale]
                                       }
                                       isDropdown
                                       dropdownLink={[
                                          localeLinkNavbar.about[router.locale],
                                          localeLinkNavbar.career[
                                             router.locale
                                          ],
                                          localeLinkNavbar.blog[router.locale],
                                       ]}
                                    />
                                    <NavLinkBundle
                                       passContent={
                                          localeLinkNavbar.contactUS[
                                             router.locale
                                          ]
                                       }
                                    />
                                 </ul>
                              </>
                           ) : null}

                           <div
                              className={
                                 'ps-0 ps-lg-5 pe-0 mt-4 mt-lg-0' +
                                 (isCheckCoveragePlatform ? ' ms-auto' : '')
                              }>
                              <Location />
                           </div>

                           <ul className="navbar-nav btn-login-container mt-4 mt-lg-0">
                              <li>
                                 <Link
                                    href="https://customer.globalxtreme.net/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`d-block text-center ${
                                       (isNavbarSticky &&
                                          !isMobileMacthMedia) ||
                                       isNavBlackStyle
                                          ? 'btn-login-secondary'
                                          : 'btn-login '
                                    }`}>
                                    Log In
                                 </Link>
                              </li>
                           </ul>

                           <ul className="navbar-nav d-flex flex-row align-items-center justify-content-center space-language mt-4 mt-lg-0">
                              <li
                                 className={
                                    'nav-lang-btn ' +
                                    (isNavbarSticky ||
                                    isNavBlackStyle ||
                                    isMobileMacthMedia
                                       ? 'text-black text-content-blur'
                                       : 'text-white') +
                                    (__locale.data === 'en' ? ' active' : '')
                                 }
                                 onClick={() => _handleSwitchLang('en')}>
                                 EN
                              </li>
                              <li
                                 className={
                                    'px-3 ' +
                                    (isNavbarSticky ||
                                    isNavBlackStyle ||
                                    isMobileMacthMedia
                                       ? 'text-black text-content-blur'
                                       : 'text-white')
                                 }>
                                 |
                              </li>
                              <li
                                 className={
                                    'nav-lang-btn ' +
                                    (isNavbarSticky ||
                                    isNavBlackStyle ||
                                    isMobileMacthMedia
                                       ? 'text-black text-content-blur'
                                       : 'text-white') +
                                    (__locale.data === 'id' ? ' active' : '')
                                 }
                                 onClick={() => _handleSwitchLang('id')}>
                                 ID
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </nav>
            </div>
         </header>

         <AnimatePresence>
            {isCollapsed && (
               <motion.div
                  className="menu_container px-4 px-md-5 d-lg-none"
                  variants={item}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: '100vh', opacity: 1, inset: 0 }}
                  transition={{ duration: 0.5 }}
                  exit="exit">
                  <div className="wp-menu-mobile">
                     {isCollapsed ? (
                        <Link href="/" className="navbar-brand border-0 p-0">
                           <img
                              src={pathLogoBlackNoSlogan}
                              // src="/images/logo/logo-gx-black.svg"
                              alt="Logo GlobalXtreme"
                              className="custom-gx-header-logo"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />
                        </Link>
                     ) : null}

                     {!isCheckCoveragePlatform ? (
                        <ul className="navbar-nav navigation-menu w-100">
                           <MotionNavLinkBundle
                              passContent={localeLinkNavbar.home[router.locale]}
                              transitionDelay=".8"
                              exitYDelay=".7"
                           />
                           <MotionNavLinkBundle
                              passContent={
                                 localeLinkNavbar.product[router.locale]
                              }
                              transitionDelay=".7"
                              exitYDelay=".6"
                              isDropdown
                              dropdownLink={packetList}
                           />
                           <MotionNavLinkBundle
                              passContent={
                                 localeLinkNavbar.about[router.locale]
                              }
                              transitionDelay=".6"
                              exitYDelay=".5"
                              isDropdown
                              dropdownLink={[
                                 localeLinkNavbar.about[router.locale],
                                 localeLinkNavbar.career[router.locale],
                                 localeLinkNavbar.blog[router.locale],
                              ]}
                           />
                           <MotionNavLinkBundle
                              passContent={
                                 localeLinkNavbar.contactUS[router.locale]
                              }
                              transitionDelay=".5"
                              exitYDelay=".4"
                           />
                           <MotionNavLinkBundle
                              passContent={{
                                 name: 'Log In',
                                 link: 'https://customer.globalxtreme.net/',
                              }}
                              transitionDelay=".4"
                              exitYDelay=".3"
                              isTargetBlank
                              rel="noreferrer"
                           />
                        </ul>
                     ) : null}
                  </div>

                  <div className="position-static wp-menu-mobile-footer w-100 d-flex justify-content-between align-items-center py-4 mt-n-8rem">
                     <motion.div
                        className="w-100 me-5"
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        exit={{
                           opacity: 0,
                           x: 80,
                           transition: {
                              ease: 'easeInOut',
                              delay: 0.3,
                           },
                        }}>
                        <Location
                           menuPlacementTop
                           _handleCollapsedNavbar={_handleCollapsedNavbar}
                        />
                     </motion.div>

                     <motion.ul
                        className="navbar-nav w-auto d-flex flex-row align-items-center justify-content-center space-language"
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        exit={{
                           opacity: 0,
                           x: 80,
                           transition: {
                              ease: 'easeInOut',
                              delay: 0.1,
                           },
                        }}>
                        <li
                           className={
                              'nav-lang-btn ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white') +
                              (__locale.data === 'en' ? ' active' : '')
                           }
                           onClick={() => _handleSwitchLang('en')}>
                           EN
                        </li>
                        <li
                           className={
                              'px-3 ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white')
                           }>
                           |
                        </li>
                        <li
                           className={
                              'nav-lang-btn ' +
                              (isNavbarSticky ||
                              isNavBlackStyle ||
                              isMobileMacthMedia
                                 ? 'text-black text-content-blur'
                                 : 'text-white') +
                              (__locale.data === 'id' ? ' active' : '')
                           }
                           onClick={() => _handleSwitchLang('id')}>
                           ID
                        </li>
                     </motion.ul>
                  </div>
               </motion.div>
            )}
         </AnimatePresence>
      </div>
   ) : (
      <NavbarMobile
         isNavBlackStyle={isNavBlackStyle}
         isCheckCoveragePlatform={isCheckCoveragePlatform}
      />
   )
}
export default NavbarDefault
